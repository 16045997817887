<template>
  <div style="width: 100%;">
    <div v-for="(option, index) in options" :key="index">
      <div v-if="option.name === 'SizeA'">
        {{ option.name }}
        <div v-if="option.values.length > 4">
          <select v-model="selected[option.name]" @change="changeOption">
            <option selected disabled>Select a {{ option.name }}</option>
            <option v-for="(value, index) in option.values" :key="index" :selected="index === 0 ? true : false">{{ index }} {{ value.value }}</option>
          </select>
        </div>
        <div v-else>
          <button v-for="(value, index) in option.values" :key="index">{{ value.value }}</button>
        </div>
      </div>
      <div v-else>
        {{ option.name }}
        <div>
          <select v-model="selected[option.name]" @change="changeOption">
            <option selected disabled>Select a {{ option.name }}</option>
            <option v-for="(value, index) in option.values" :key="index" :selected="index === 0 ? true : false">{{ value.value }}</option>
          </select>
        </div>
      </div>
    </div>
    <button class="cta" v-on:click="selectProduct()">Create now</button>
  </div>
</template>

<script>
import ShopifyHelper from '@/helpers/shopify.js'
import newStore from '@/store/newstore'
export default {
  props: {
    options: Array,
    index: Number,
    productId: String
  },
  data () {
    return {
      selected: []
    }
  },
  methods: {
    selectProductA (product) {
      console.log(product, this.selected)
    },
    addToCart () {
      ShopifyHelper.addToCart()
    },
    selectProduct (product) {
      product = this.activeProduct
      const id = product.newID
      this.$store.commit('activeProduct', this.productVariant)
      const vid = this.productVariant[0].newID
      console.log(id, vid)
      // this.$router.push('/products/' + this.activeProduct.newID + '?vid=' + this.productVariant.newID)
      this.$router.push({ path: `/products/${id}?vid=${vid}` }) // -> /user/123

      // this.$router.push({ name: 'Product', params: { id: this.activeProduct.newID }, query: { vid: this.productVariant.newID } })

      const metaValue = JSON.parse(this.productVariant[0].metafields[0].value)

      const meta = {}
      const artboards = 1

      meta.sizes = {}
      meta.sizes.w = Number(metaValue[0].width)
      meta.sizes.h = Number(metaValue[0].height)
      meta.sizes.pw = Number(metaValue[0].print_width)
      meta.sizes.ph = Number(metaValue[0].print_height)
      meta.sizes.bx = Number(metaValue[0].print_bleed)
      meta.sizes.by = Number(metaValue[0].print_bleed)

      let designType = 'Design'
      if (product.title.includes('Instawall')) {
        designType = 'Instawall'
      }

      const content = []
      for (let i = 0; i < artboards; i++) {
        content[i] = {
          sizes: {
            w: meta.sizes.w,
            h: meta.sizes.h,
            pw: meta.sizes.pw,
            ph: meta.sizes.ph,
            bx: meta.sizes.bx,
            by: meta.sizes.by
          },
          grid: 5,
          gridSize: 0,
          type: designType,
          background: 'white',
          locked: true,
          percentage: 0,
          count: 3,
          items: []
        }
      }
      newStore.dispatch('selectDesign', {
        title: product.title,
        productID: product.id,
        tags: 'life, happiness, blue',
        category: 'reference',
        id: 'RHFK201235',
        reference_id: '123234324544',
        grid: 'none',
        type: designType,
        thumbnail: 'url',
        created: 'date_time',
        modified: 'date_time',
        selected: 1002,
        finished: 254,
        order: 15,
        content: content
      })
      this.$store.commit('closeComponent')
    },
    changeOption () {
      // const product = this.productList.filter(({ product }) => product.id === this.productId)
      // console.log(product)
      // const product = this.productList[this.index].variants
      // console.log(this.product[0])
      // console.log(this.product.variants)
      console.log(this.productVariant)
      // console.log(this.selected)
    }
  },
  computed: {
    activeProduct () {
      return this.$store.state.productsStore.product
    },
    product () {
      // var filtered = this.$store.state.productsStore.productList[this.index]
      return this.$store.state.productsStore.productList.filter(product => {
        return product.id === this.productId
      })
    },
    productVariant () {
      // const options = this.selected.toLowerCase()
      const size = this.selected.Size
      // const material = this.selected.Material
      // const accessoire = this.selected.Accessoire
      const sizeVariants = this.product[0].variants.filter(variant => {
        const title = variant.title.replace(/ /g, '')
        return title.includes(size.replace(/ /g, ''))
      })

      console.log(sizeVariants)
      const material = this.selected.Material
      const materialVariants = sizeVariants.filter(variant => {
        const title = variant.title.replace(/ /g, '')
        return title.includes(material.replace(/ /g, ''))
      })
      console.log(materialVariants)

      const accessoire = this.selected.Accessory
      console.log(accessoire)
      const accessoireVariants = materialVariants.filter(variant => {
        const title = variant.title.replace(/ /g, '')
        return title.includes(accessoire.replace(/ /g, ''))
      })
      console.log(accessoireVariants)
      return accessoireVariants
      // return this.product[0].variants.filter(variant => {
      //   const title = variant.title.replace(/ /g, '')
      //   return title.includes(size.replace(/ /g, ''))
      // })
    }
  }
}
</script>

<style>

</style>
