<template>
  <div class="overlay active">
    <div class="background" @click="closePopup"></div>
    <div class="dialog">
       <h3>Choose your options</h3>
      <ProductListVue />
    </div>
  </div>
</template>

<script>
import ProductListVue from './ProductList.vue'

export default {
  data () {
    return {
      menu: {
        icon: 'web-grid',
        status: 'active',
        thumbnail: 'https://thumbs.dreamstime.com/z/halloween-background-moon-dead-tree-halloween-background-moon-dead-tree-124918442.jpg',
        title: 'My Designs',
        type: 'user'
      }
    }
  },
  components: {
    ProductListVue
  },
  methods: {
    closePopup () {
      this.$store.commit('closeComponent')
    }
  }
}
</script>

<style lang="scss" scoped>

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 10000;
  display: none;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;

  &.active {
    display: flex;
  }
}

.overlay .dialog {
  width: 300px;
  margin-top: 40px;
  margin-bottom: 00px;
  height: auto;
  min-height: auto;
  background: white;
  border-radius: 24px;
  padding: 20px 20px;
  align-self: initial;
  h3 {
    padding-bottom:0;
  }
 @media only screen and (max-width: 760px) {
      width: 100%;
      border-radius: 24px 24px 0 0;
    }
}

.menu {
  position: sticky;
  top: 0px;
  height: 40px;
  font-size: 0.75em;
  padding: 20px;
  background: white;
  z-index: 99;
  text-align: left;
  a {
    padding: 8px 12px;
    text-decoration: none;
    color: black;
    &.router-link-exact-active {
      background: #f5f5f5;
      border-radius: 99px;
    }
  }

}
</style>
