<template>
  <div class="product-list container">
      <div v-for="(product, index) in productList" :key="index">
         <div v-on:click="selectProduct(product)"> {{ product.title }} </div>
      </div>
    <div class="product-list-item" v-if="activeProduct">
              <!-- <router-link :to="{ name: 'Product', params: { id: product.handle }}">{{ product.title }}</router-link> -->
              <div  v-for="(image, index) in activeProduct.images " :key="index" v-on:click="selectProduct(activeProduct)">
                <img v-if="index === 0" :src="image.src | capitalize" width="250" />
              </div>
              <div style="display:flex; width: 100%; flex-direction: column;">
                <h4 style="margin-right: auto;">{{ activeProduct.title }}</h4>
                <ProductOptions :options="activeProduct.options" :productId="activeProduct.id"></ProductOptions>
              </div>
      </div>
  </div>
</template>

<script>

import store from '@/store/interface/'
import ProductOptions from './productOptions'

import newStore from '@/store/newstore'

export default {
  components: {
    ProductOptions
  },
  data () {
    return {
      productId: null,
      variantId: null,
      userKey: null,
      products: [],
      posts: []
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      const extenstion = value.split('.').pop()
      const dark = value.substr(0, value.lastIndexOf('.')) + '_300x.' + extenstion
      return dark
    }
  },
  computed: {
    activeProduct () {
      return this.$store.state.productsStore.product
    },
    productList () {
      let filtered = []
      if (this.$store.state.productsStore) {
        filtered = this.$store.state.productsStore.productList
      }
      return filtered
    },
    activeBoard () {
      return newStore.state.activeDesign
      // return this.$store.state.artBoardStore.artBoards
    },
    overlayState () {
      return store.overlay
    }
  },
  methods: {
    changeOption () {
      console.log(this.selected)
    },
    selectProduct (product) {
      this.$store.commit('activeProduct', product)
      newStore.state.activeElement = null
      console.log(this.userKey)
      // this.$store.state.artBoardStore.activeElement = null
      let metaValue, sku, productId, variantId
      if (this.variantId != null) {
        const selected = product.variants.filter(variant => {
          return variant.newID === this.variantId
        })
        metaValue = JSON.parse(selected[0].metafields[0].value)
        sku = selected[0].sku
        productId = product.newID
        variantId = selected[0].newID
      } else {
        sku = product.variants[0].sku
        variantId = product.variants[0].newID
        productId = product.newID
        metaValue = JSON.parse(product.variants[0].metafields[0].value)
        // variantTitle = product.variants[0].name
      }
      const meta = {}
      var artboards = 1

      meta.sizes = {}
      meta.sizes.w = Number(metaValue[0].width)
      meta.sizes.h = Number(metaValue[0].height)
      meta.sizes.pw = Number(metaValue[0].print_width)
      meta.sizes.ph = Number(metaValue[0].print_height)
      meta.sizes.bx = Number(metaValue[0].print_bleed)
      meta.sizes.by = Number(metaValue[0].print_bleed)

      var otherProduct = false
      if (this.activeBoard) {
        if (this.activeBoard.productID !== productId && this.activeBoard.variantID !== variantId) {
          otherProduct = true
        }
      }

      if (this.activeBoard && otherProduct) {
        this.$confirm({
          message: 'Wil je verder met dit design? Of wil je opnieuw starten?',
          button: {
            no: 'Opnieuw',
            yes: 'Ga verder'
          },
          callback: confirm => {
            if (confirm) {
              // let designType = 'Free'
              // if (product.title.includes('Instawall')) {
              //   designType = 'Grid'
              // }
              // this.activeBoard.content[0].type = designType
              const items = this.activeBoard.content[0].items
              // const items = []
              this.activeBoard.productID = productId
              this.activeBoard.variantID = variantId
              this.activeBoard.content[0].items = []
              this.activeBoard.content[0].sizes = {
                w: meta.sizes.w,
                h: meta.sizes.h,
                pw: meta.sizes.pw,
                ph: meta.sizes.ph,
                bx: meta.sizes.bx,
                by: meta.sizes.by
              }
              this.activeBoard.content[0].items = items
              this.activeBoard.sku = sku
            } else {
              return false
            }
          }
        })
      } else {
        let designType = 'Free'

        if (product.title.includes('Instawall')) {
          designType = 'Free'
        }
        const content = []
        for (let i = 0; i < artboards; i++) {
          content[i] = {
            sizes: {
              w: meta.sizes.w,
              h: meta.sizes.h,
              pw: meta.sizes.pw,
              ph: meta.sizes.ph,
              bx: meta.sizes.bx,
              by: meta.sizes.by
            },
            grid: 5,
            gridSize: 0,
            type: designType,
            background: 'white',
            locked: true,
            percentage: 0,
            count: 3,
            items: []
          }
        }

        // this.$store.commit('activeArtBoard', {
        newStore.dispatch('selectDesign', {
          title: product.title,
          productID: productId,
          variantID: variantId,
          tags: 'life, happiness, blue',
          category: 'reference',
          id: 'RHFK201235',
          grid: 'none',
          reference_id: '123234324544',
          type: designType,
          sku: sku,
          thumbnail: 'url',
          created: 'date_time',
          modified: 'date_time',
          selected: 1002,
          finished: 254,
          order: 15,
          content: content
        })
      }
      this.$store.commit('closeComponent')
    }
  },
  watch: {
    $route (to, from) {
      this.productId = to.params.id
      // react to route changes...
    },
    productList (old, from) {
      var selected = old.filter(d => d.newID === this.productId)
      if (this.activeBoard && this.activeBoard.productID === selected[0]) {
      } else {
        this.selectProduct(selected[0], this.variantId)
      }
    }
  },
  mounted () {
    // console.log(this.$route.params.id.toString())
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      this.variantId = this.$route.query.vid
      this.userKey = this.$route.query.key
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list {
  &.container {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    gap: 22px;
    height: auto;
  }
  .product-list-item {
    padding: 0px;
    flex: 1 0 20%;
    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
    h4 {
      padding: 0;
      margin: 0;
      max-width: 180px;
    }

    @media only screen and (max-width: 760px) {
      flex: 2;
    }
  }
 @media only screen and (max-width: 760px) {
      gap: 5px;
    }
}
</style>
