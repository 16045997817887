import Vue from 'vue'

const state = Vue.observable({
  // TODO Create complete state of the applications Interface here, API Call to save state to user specific, and load.
  sidebar: false,
  overlay: false,
  max_size: function () { return 500 }
})
export const Sidebar = {
  toggle () {
    state.sidebar = !state.sidebar
  },
  activate () {
    state.sidebar = true
  }
}
export const Overlay = {
  toggle () {
    state.overlay = !state.overlay
  }
}
export const decrement = () => state.counter--
export default state
