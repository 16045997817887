import Client from 'shopify-buy/index.unoptimized.umd'
import atob from 'atob'
const client = Client.buildClient({
  domain: 'instawallprints.myshopify.com',
  storefrontAccessToken: '4191bce3a7438deeb6012eaa1a142f75'
}
)
export default class Shopify {
  getProduct (store, id) {
    var products = []
    const query = client.graphQLClient.query((root) => {
      root.addConnection('products', {
        args: { first: 1, query: 'id:"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3OTQ5NTIwNTY4Nzc"' }
      }, (Product) => {
        Product.add('title')
        Product.add('tags') // Add fields to be returned
        Product.addConnection('images', { args: { first: 250 } }, (images) => {
          images.add('src')
          images.add('id')
          images.add('altText')
        })
        Product.addConnection('variants', { args: { first: 250 } }, (variants) => {
          variants.add('product')
          variants.add('title')
          variants.add('price')
          variants.add('sku')
          variants.add('image', (image) => {
            image.add('src')
            image.add('id')
            image.add('altText')
          })
        })
      })
    })
    client.graphQLClient.send(query).then(({ model, data }) => {
    // this.productsStore.push(model)
      const producten = model.products
      for (const product in producten) {
        products.push(producten[product])
      }
      store.commit('productList', products)
      return 'succes'
    })
    // const productId = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3OTQ5NTIwNTY4Nzc='

    // client.product.fetch(productId).then((product) => {
    //   // Do something with the product
    //   console.log(product)
    //   products.push(product)
    //   store.commit('productList', products)
    // })
  }

  getAll () {
    client.product.fetchAll().then((products) => {
      // Do something with the products
      console.log(products)
    })
    // Fetch all products in your shop
    // Build a custom products query using the unoptimized version of the SDK
    const productsQuery = client.graphQLClient.query((root) => {
      root.addConnection('products', { args: { first: 25 } }, (product) => {
        product.add('title')
        product.add('tags') // Add fields to be returned
        product.addConnection('metafields', { args: { first: 10 } }, (metafield) => {
          metafield.add('key')
          metafield.add('value')
        })
        product.addConnection('variants', { args: { first: 250 } }, (variants) => {
          variants.add('product')
          variants.add('title')
          variants.add('price')
          variants.add('sku')
          variants.add('image', (image) => {
            image.add('src')
            image.add('id')
            image.add('altText')
          })
          variants.addConnection('metafields', { args: { first: 10 } }, (metafield) => {
            metafield.add('key')
            metafield.add('value')
          })
        })
      })
    })

    // Call the send method with the custom products query
    client.graphQLClient.send(productsQuery).then(({ model, data }) => {
      // Do something with the products
      // console.log(model)
    })
  }

  createCheckout () {
    // Create an empty checkout
    client.checkout.create().then((checkout) => {
      // Do something with the checkout
      console.log(checkout)
    })
  }

  addToCart (id) {
    const decoded = atob(id)
    const shopifyId = decoded.split('/').pop()
    // return parseInt(shopifyId)
    // console.log(decoded)

    window.parent.postMessage(shopifyId, '*')
    // const checkoutId = 'Z2lkOi8vc2hvcGlmeS9DaGVja291dC8yMzgxMGFkYTUwNTdhMWYxYjdmMDQwYmNhNmYzMjFiOD9rZXk9NmZhMWE0ZTY3N2ZiNmZiYjIwZmVmYzA1NjEwNzRlMDM=' // ID of an existing checkout
    // const lineItemsToAdd = [
    //   {
    //     variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTY1Nzk4ODU1NDc5Nw==',
    //     quantity: 1,
    //     customAttributes: [{ key: 'MyKey', value: 'MyValue' }]
    //   }
    // ]

    // // Add an item to the checkout
    // client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
    //   // Do something with the updated checkout
    //   console.log(checkout) // Array with one additional line item
    // })
  }

  getProducts (store) {
    var products = []
    const query = client.graphQLClient.query((root) => {
      root.addConnection('products', {
        args: { first: 250 }
      }, (Product) => {
        Product.add('id')
        Product.add('title')
        Product.add('handle')
        Product.add('tags') // Add fields to be returned
        Product.addConnection('images', { args: { first: 250 } }, (images) => {
          images.add('src')
          images.add('id')
          images.add('altText')
        })
        Product.add('options', (option) => {
          option.add('name')
          option.add('values')
        })
        Product.addConnection('variants', { args: { first: 250 } }, (variants) => {
          variants.add('product')
          variants.add('title')
          variants.add('price')
          variants.add('sku')
          variants.add('image', (image) => {
            image.add('src')
            image.add('id')
            image.add('altText')
          })
          variants.addConnection('metafields', { args: { first: 10 } }, (metafield) => {
            metafield.add('key')
            metafield.add('value')
          })
        })
      })
    })
    client.graphQLClient.send(query).then(({ model, data }) => {
    // this.productsStore.push(model)
      const producten = model.products
      for (const product in producten) {
        // product.id = atob(producten[product].id).split('/').pop()
        // var element = []
        // console.log(atob(old[0].id))
        // const shopifyId = decoded.split('/').pop()
        if (producten[product].name === 'ProductConnection') {

        } else {
          products.push(producten[product])
        }
      }
      for (const adjust in products) {
        products[adjust].newID = atob(products[adjust].id).split('/').pop()

        for (const adjustTwo in products[adjust].variants) {
          if (products[adjust].variants[adjustTwo].id) {
            products[adjust].variants[adjustTwo].newID = atob(products[adjust].variants[adjustTwo].id).split('/').pop()
          }
        }
      }
      store.commit('productList', products)
      return 'succes'
    })
  }

  sendRequest () {
    // Use vue-resource or any other http library to send your request
  }
}
