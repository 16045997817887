<template>
  <div>
    <Products></Products>
  </div>
</template>

<script>
import Products from '@/views/Products'

export default {
  components: {
    Products
  }
}
</script>

<style>

</style>
